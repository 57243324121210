﻿@import url('https://fonts.googleapis.com/css?family=Manrope:400,600,700|Lora:500');
@import 'node_modules/tachyons/css/tachyons';
@import 'node_modules/react-responsive-carousel/lib/styles/carousel';
@import 'node_modules/react-phone-number-input/style';
@import 'node_modules/react-leaflet-markercluster/dist/styles.min';
// @import 'node_modules/feeder-react-feedback/dist/feeder-react-feedback'; // import stylesheet
// @import 'node_modules/react-mini-feedback/dist/index';

// @import "node_modules/react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// @import 'node_modules/dropzone/dist/dropzone';

html,
body {
  width: 100%;
  height: 100%;
  // background-color: #fff;
  background-color: #F6F6F5;
}

.splash {
  background-image: url('../../assets/img/start/splash--fish-food@2x.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.unsplash {
  background-image: url('../../assets/img/unsplash.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.sand {
  background-image: url('../../assets/img/sand.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.burn {
  background-image: url('../../assets/img/burn.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.unsplash-1 {
  background-image: url('../../assets/img/unsplash_1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.extra-visibility-matches {
  background-image: url('../../assets/img/extra-visibility-matches.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 150px;
}

.recommended {
  position: absolute;
  top:0;
  right:0;
  margin: 2%;
  color: black;
  z-index: 1;
}

.not-found {
  background-image: url('../../assets/img/404_image3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}
// .splash-info {
//     margin-top: -10%;
// }

.splash-info__bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  z-index: 1;
}

.splash-info__content {
  z-index: 2;
}

.root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
}

.dropzone {
  min-height: auto;
}

.item-comma-separated {
  &::after {
    content: ',';
  }
  &:last-of-type::after {
    content: '';
  }
}

.f-fl {
  position: relative;

  > .f-el::placeholder {
    color: transparent;
  }

  > .f-el + label {
    position: absolute;
    top: 0px;
    left: 0;
    cursor: text;
    transition: all 200ms;
    color: #757575;
  }

  > .f-el.has-value + label,
  > .f-el:focus + label {
    padding-top: 2px;
    padding-bottom: 0;
    font-size: 60%;
    color: #357edd;
  }
}

.f-el:focus {
  outline: 1px solid #357edd;
  outline-offset: 0;
}

.f-fl--select {
  .f-el {
    appearance: none;
  }
}

.pa2_5 {
  padding: 0.75rem;
}

.pt2_5 {
  padding-top: 0.75rem;
}

.pb2_5 {
  padding-bottom: 0.75rem;
}

.painput {
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
}

.modal {
  display: flex;
  z-index: 5;
  position: fixed;
}

.modal,
.modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal__surface {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 24px;
  padding: 1rem;

  &.modal__surface--narrow {
    max-width: 350px;
  }

  &.modal__surface--wide {
    max-width: 750px;
  }
}

.modal__surface__black {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 600px;
  color: white;
  background-color: black;
  border: 1px solid black;
  border-radius: 3px;
  padding: 1rem;

  &.modal__surface--narrow {
    max-width: 350px;
  }
}

.modal__backdrop {
  opacity: 0.3;
  z-index: -1;
  background-color: #000;
}

.modal__footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.modal__header {
  padding-right: 16px;
}

.button__user-menu {
  display: none;
  right: 0;
  width: 200px;
  z-index: 10;
}

.button__user-wrapper:hover .button__user-menu {
  display: block;
}

.select-list__icon {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -10px;
  right: 5px;
}

.disabled-cursor {
  &:disabled {
    cursor: not-allowed;
  }
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.grid-3-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
}

.w-45 {
  width: 45%;
}

.w-48 {
  width: 48%;
}

@media screen and (min-width: 30em) {
  .w-48-ns {
    width: 48%;
  }
  .w-31-ns {
    width: 31%;
  }
  .w-22-ns {
    width: 22%;
  }
}

.antialias {
  -webkit-font-smoothing: antialiased;
}

.pv3_5 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.animate {
  transition: all 0.3s ease;
}

.main-search {
  &.is-active::placeholder {
    @extend .black-70;
  }

  &::placeholder {
    @extend .white-80;
  }
}

.has-tooltip {
  cursor: help;

  &:hover {
    .tooltip {
      display: block;
    }
  }
}
.tooltip {
  display: none;
  bottom: 100%;
}

.min-w-0 {
  min-width: 0;
}

.header-bg {
  background-color: #9affcc;
}

.max-content {
  width: max-content;
}

.count-down {
  &__item {
    display: inline-block;
  }
  &__time-box {
    padding: 7px;
    text-align: center;
    width: 45px;
    display: block;
    font-size: 30px;
    font-weight: 600;
    border: 1px solid;
  }
  &__label {
    text-align: center;
    display: block;
    font-size: 12px;
  }
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #F6F6F5;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px #F6F6F5 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.carousel .slide {
  background: transparent;
}

.carousel.carousel-slider {
  overflow: visible;
}

.leaflet-container,
.leaflet-top,
.leaflet-bottom,
.leaflet-pane {
  z-index: 4;
}

.leaflet-popup-content {
  margin: 0;
}

.leaflet-popup-content p {
  margin: inherit;
}

.leaflet-container a {
  color: inherit;
}

.leaflet-container .white .MuiButton-label {
  color: #fff;
}

.leaflet-popup-content {
  width: auto !important;
}

.map-marker-images {
  .slider-wrapper {
    border-radius: 12px 12px 0 0;
  }
}